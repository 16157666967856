import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: not typesafe yet }}\n<Nav::Notification::Wrapper\n  @notification={{this.notification}}\n  @notificationTitle={{this.notificationTitle}}\n  @onClick={{this.openPost}}\n  @onInit={{perform this.fetchPost}}\n>\n  <p class=\"cNO--itemContent__comment u--m__b__0\">\n    {{html-safe this.comment}}\n  </p>\n</Nav::Notification::Wrapper>", {"contents":"{{! @glint-nocheck: not typesafe yet }}\n<Nav::Notification::Wrapper\n  @notification={{this.notification}}\n  @notificationTitle={{this.notificationTitle}}\n  @onClick={{this.openPost}}\n  @onInit={{perform this.fetchPost}}\n>\n  <p class=\"cNO--itemContent__comment u--m__b__0\">\n    {{html-safe this.comment}}\n  </p>\n</Nav::Notification::Wrapper>","moduleName":"later/components/nav/notification/external-post-commented.hbs","parseOptions":{"srcName":"later/components/nav/notification/external-post-commented.hbs"}});
import { action } from '@ember/object';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import { ErrorSeverity } from 'later/services/errors';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';

import type RouterService from '@ember/routing/router-service';
import type { SafeString } from '@ember/template/-private/handlebars';
import type StoreService from '@ember-data/store';
import type { FeedItem } from '@knocklabs/client';
import type IntlService from 'ember-intl/services/intl';
import type GramModel from 'later/models/gram';
import type SocialProfileModel from 'later/models/social-profile';
import type AlertsService from 'later/services/alerts';
import type ErrorsService from 'later/services/errors';
import type EventsService from 'later/services/events';
import type NotificationsService from 'later/services/notifications';
import type SegmentService from 'later/services/segment';
import type { Maybe } from 'shared/types';

interface ExternalPostCommentedComponentSignature {
  Element: HTMLDivElement;
  Args: {
    close: () => void;
    notification: FeedItem<ExternalPostCommentedNotificationData>;
  };
  Blocks: {
    default: [];
  };
}

interface ExternalPostCommentedNotificationData {
  comment: string;
  commenter_name: string;
  group_id: number;
  group_name: string;
  initiator: string;
  post_caption: string;
  post_id: number;
  post_thumbnail_url: string;
  social_profile_id: number;
  social_profile_name: string;
  social_profile_type: string;
  is_draft: boolean;
  scheduled_time: number;
}

export default class ExternalPostCommentedComponent extends Component<ExternalPostCommentedComponentSignature> {
  @service declare alerts: AlertsService;
  @service declare errors: ErrorsService;
  @service declare events: EventsService;
  @service declare intl: IntlService;
  @service declare notifications: NotificationsService;
  @service declare router: RouterService;
  @service declare segment: SegmentService;
  @service declare store: StoreService;

  @tracked post: Maybe<GramModel>;

  constructor(owner: unknown, args: ExternalPostCommentedComponentSignature['Args']) {
    super(owner, args);

    this.events.on('post:updated', (event) => {
      if (this.post?.id == event?.data.id) {
        this.fetchPost.perform();
      }
    });

    this.events.on('post:deleted', (event) => {
      if (this.post?.id == event.data.id) {
        this.post = undefined;
      }
    });
  }
  get notification(): FeedItem<ExternalPostCommentedNotificationData> {
    return this.args.notification;
  }

  get notificationData(): ExternalPostCommentedNotificationData | null {
    return this.notification.data;
  }

  get notificationTitle(): SafeString {
    return htmlSafe(
      this.intl.t('notifications.components.external_post_commented.title', {
        author: this.notificationData?.commenter_name || '',
        social_profile_name: this.socialProfile?.displayNickname || this.notificationData?.social_profile_name || ''
      })
    );
  }

  get socialProfile(): Maybe<SocialProfileModel> {
    if (this.notificationData?.social_profile_id) {
      return this.store.peekRecord('social-profile', this.notificationData.social_profile_id);
    }

    return undefined;
  }

  get comment(): string {
    return this.notificationData?.comment?.replace(/\n/g, '<br>') || '';
  }

  @action
  openPost(): void {
    this.notifications.markAsRead(this.args.notification);

    this.segment.track(SegmentEventTypes.OpenedInAppNotification, {
      post_id: this.post?.id || String(this.notificationData?.post_id) || '',
      type: 'external-comment'
    });

    if (!this.post?.id) {
      this.alerts.alert(this.intl.t('notifications.errors.post_not_found.message'), {
        title: this.intl.t('notifications.errors.post_not_found.title'),
        preventDuplicates: true
      });
      return;
    }

    const route = this.router.currentRouteName.includes('cluster.schedule.list')
      ? 'cluster.schedule.list.post.edit'
      : 'cluster.schedule.calendar.post.edit';

    this.router.transitionTo(route, this.post.id, {
      queryParams: {
        openPostActivities: true
      }
    });
    this.args.close();
  }

  fetchPost = task(async () => {
    if (!this.notificationData?.post_id) {
      return;
    }

    try {
      const postFromStore = this.store.peekRecord('gram', this.notificationData.post_id);

      if (!postFromStore) {
        const postQuery = (await this.store.query('gram', {
          query_type: 'all_by_id',
          ids: [this.notificationData.post_id]
        })) as unknown as GramModel[];

        this.post = postQuery.firstObject;
        return;
      }

      this.post = postFromStore;
    } catch (error) {
      this.errors.log(
        `Unable to fetch post ${this.notificationData.post_id} for notification`,
        { data: error },
        ErrorSeverity.Info
      );
    }
  });
}
