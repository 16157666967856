import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import moment, { type Moment } from 'moment';
import { tracked } from 'tracked-built-ins';

import { GramState } from 'later/models/gram';
import { FetchableRecordTypes } from 'later/services/schedule/fetch-within-dates';
import { PlatformPostType, TEXT_POST_TYPE } from 'later/utils/constants';
import { SegmentEventTypes } from 'later/utils/constants/segment-events';
import isWithinDates from 'later/utils/is-within-dates';

import type MutableArray from '@ember/array/mutable';
import type RouterService from '@ember/routing/router-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type GramModel from 'later/models/gram';
import type MediaItemModel from 'later/models/media-item';
import type SocialProfileModel from 'later/models/social-profile';
import type AuthService from 'later/services/auth';
import type CacheService from 'later/services/cache';
import type ErrorsService from 'later/services/errors';
import type MediaLibraryService from 'later/services/media-library';
import type PostNavigationService from 'later/services/post-navigation';
import type FetchWithinDatesService from 'later/services/schedule/fetch-within-dates';
import type PostsPendingApprovalService from 'later/services/schedule/posts-pending-approval';
import type SegmentService from 'later/services/segment';
import type SelectedSocialProfilesService from 'later/services/selected-social-profiles';
import type UserConfigService from 'later/services/user-config';
import type { EmberDropMultiSelectOption } from 'shared/components/shared/ember-drop-multi-select';
import type { Maybe, UntypedService } from 'shared/types';

export default class ListController extends Controller {
  @service declare auth: AuthService;
  @service declare cache: CacheService;
  @service declare errors: ErrorsService;
  @service declare intl: IntlService;
  @service declare mediaLibrary: MediaLibraryService;
  @service declare postNavigation: PostNavigationService;
  @service declare router: RouterService;
  @service declare schedule: UntypedService;
  @service declare segment: SegmentService;
  @service declare selectedSocialProfiles: SelectedSocialProfilesService;
  @service declare store: StoreService;
  @service('schedule/posts-pending-approval') declare postsPendingApproval: PostsPendingApprovalService;
  @service('schedule/fetch-within-dates') declare fetchWithinDates: FetchWithinDatesService;
  @service declare userConfig: UserConfigService;

  @tracked selectedPostStates: string[] = tracked([]);
  @tracked selectedInstagramPostTypes: string[] = tracked([]);
  @tracked selectedSortOptions: string[] = tracked([this.sortOptions[1]?.name]);
  @tracked startDate: Moment = moment().tz(this.timeZone).startOf('day');
  @tracked endDate: Moment = moment().tz(this.timeZone).add(7, 'days').endOf('day');

  // Note: Expects a comma separated list of PostState names
  queryParams = ['initial_states'];
  declare initial_states: string | undefined;

  constructor(...args: Record<string, unknown>[]) {
    super(...args);
    this.#trackViewedPage();
  }

  get dateRange(): { start: Date; end: Date } {
    return {
      start: this.startDate.toDate(),
      end: this.endDate.toDate()
    };
  }

  get displayPendingApprovalCount(): boolean {
    return Boolean(this.postsPendingApproval.count);
  }

  get filterCacheKey(): string {
    return `list_post_filter_${this.auth.currentUserModel?.id}_${this.auth.currentGroup?.id}`;
  }

  get instagramFilterCacheKey(): string {
    return `list_post_instagram_filter_${this.auth.currentUserModel?.id}_${this.auth.currentGroup?.id}`;
  }

  get instagramPostTypes(): EmberDropMultiSelectOption[] {
    return [
      { name: this.intl.t('shared_words.reels'), value: PlatformPostType.InstagramReel, icon: 'ig-reels-outline' },
      { name: this.intl.t('shared_words.feed_posts'), value: PlatformPostType.InstagramPost, icon: 'ig-feed' }
    ];
  }

  get isDragging(): boolean {
    return this.schedule.isDragging;
  }

  get isLoading(): boolean {
    return this.fetchWithinDates.fetchRecords.isRunning;
  }

  get pendingApprovalCount(): number {
    return this.postsPendingApproval.count;
  }

  get postDatesCacheKey(): string {
    return `list_post_dates_${this.auth.currentUserModel?.id}_${this.auth.currentGroup?.id}`;
  }

  get postsFromStore(): MutableArray<GramModel> {
    return this.store.peekAll('gram');
  }

  get approvalFilters(): EmberDropMultiSelectOption[] {
    return [
      { name: this.intl.t('post.state.internal_review_pending'), icon: 'hourglass' },
      { name: this.intl.t('post.state.internal_review_approved'), icon: 'verified' },
      { name: this.intl.t('post.state.external_review_pending'), icon: 'hourglass' },
      { name: this.intl.t('post.state.external_review_collected'), icon: 'verified' }
    ];
  }

  get postStates(): EmberDropMultiSelectOption[] {
    const defaultFilters = [
      { name: this.intl.t('post.state.draft'), value: GramState.Draft, icon: 'draft-post' },
      { name: this.intl.t('post.state.failed'), value: GramState.Failed, icon: 'danger' },
      { name: this.intl.t('post.state.published'), value: GramState.Verified, icon: 'verified' },
      { name: this.intl.t('post.state.scheduled'), value: GramState.Scheduled, icon: 'schedule' }
    ];

    return this.auth.currentAccount.canApprovalFlow ? [...defaultFilters, ...this.approvalFilters] : defaultFilters;
  }

  get selectedProfiles(): SocialProfileModel[] {
    return this.selectedSocialProfiles.profiles;
  }

  get shouldDisplayInstagramFilters(): boolean {
    return Boolean(
      !this.selectedSocialProfiles.hasMultipleSelected && this.selectedSocialProfiles.firstProfile?.isInstagram
    );
  }

  get hasPosts(): boolean {
    return Number(this.postsFromStore.length) > 0;
  }

  get posts(): MutableArray<GramModel> {
    const filteredSortedPosts = this.#filterPosts(this.postsFromStore).sortBy('scheduledTime');
    return this.selectedSortOptions.includes(this.intl.t('calendar.list.filter.sort_new_old'))
      ? filteredSortedPosts.reverse()
      : filteredSortedPosts;
  }

  get selectedAssets(): MediaItemModel[] {
    return this.mediaLibrary.selectedMedia;
  }

  get sortOrderCacheKey(): string {
    return `list_post_sort_order_${this.auth.currentUserModel?.id}_${this.auth.currentGroup?.id}`;
  }

  get timeZone(): string {
    return this.userConfig.currentTimeZone?.identifier || '';
  }

  // Note: Return a post scheduling time based on the current 'targetDate'
  // and provide this to the post service in the same format that fullcalendar would
  get postTimeFromCurrentDate(): number {
    return Number(Date.now()) / 1000;
  }

  get sortOptions(): EmberDropMultiSelectOption[] {
    return [
      { name: this.intl.t('calendar.list.filter.sort_old_new') },
      { name: this.intl.t('calendar.list.filter.sort_new_old') }
    ];
  }

  @action
  displayRequiresApproval(): void {
    this.#trackClickedApprovalList();
    const unapprovedPostState = this.postStates.find(
      (postState: EmberDropMultiSelectOption) => postState.name === this.intl.t('post.state.internal_review_pending')
    );

    this.#displayPendingApprovalDates();

    if (!unapprovedPostState || this.selectedPostStates.includes(unapprovedPostState.name)) {
      return;
    }

    this.updateSelectedPostStates(unapprovedPostState);
  }

  @action
  getFiltersFromCache(): void {
    const rawCachedSelectedPostStates: Maybe<string> = this.cache.retrieve(this.filterCacheKey);
    const rawCachedInstagramPostTypes: Maybe<string> = this.cache.retrieve(this.instagramFilterCacheKey);

    try {
      if (rawCachedSelectedPostStates) {
        const cachedSelectedPostStates = JSON.parse(rawCachedSelectedPostStates);
        this.selectedPostStates = tracked(cachedSelectedPostStates);
      }
      if (rawCachedInstagramPostTypes) {
        const cachedInstagramPostTypes = JSON.parse(rawCachedInstagramPostTypes);
        this.selectedInstagramPostTypes = tracked(cachedInstagramPostTypes);
      }
    } catch (error) {
      this.errors.log('getFiltersFromCache Error', error);
    }
  }

  @action
  getDatesFromCache(): void {
    const rawCachedPostDates: Maybe<string> = this.cache.retrieve(this.postDatesCacheKey);

    if (rawCachedPostDates) {
      try {
        const cachedPostDates = JSON.parse(rawCachedPostDates);
        this.endDate = moment(cachedPostDates.end);
        this.startDate = moment(cachedPostDates.start);
      } catch (error) {
        this.errors.log('getDatesFromCache Error', error);
      }
    }
  }

  @action
  getSortOrderFromCache(): void {
    const rawCachedSortOrder: Maybe<string> = this.cache.retrieve(this.sortOrderCacheKey);

    if (rawCachedSortOrder) {
      try {
        const cachedSortOrder = JSON.parse(rawCachedSortOrder);
        this.selectedSortOptions = tracked(cachedSortOrder);
      } catch (error) {
        this.errors.log('getSortOrderFromCache Error', error);
      }
    }
  }

  @action
  onEditPost(post: GramModel): void {
    this.#transitionToEdit(post.id);
  }

  @action
  onDrop(dragEvent: DragEvent): void {
    const itemId = this.#getItemIdFromDragEvent(dragEvent) || TEXT_POST_TYPE;
    return this.#transitionToNew(itemId);
  }

  @action
  setPostNavigation(): void {
    this.postNavigation.setPosts(this.posts.toArray());
  }

  @action
  toggleSelectedSortOption(option: EmberDropMultiSelectOption, _selected: boolean, closeEmberDrop: () => void): void {
    this.selectedSortOptions = this.selectedSortOptions.includes(option.name)
      ? tracked(this.selectedSortOptions)
      : tracked([option.name]);
    this.cache.add(this.sortOrderCacheKey, JSON.stringify(this.selectedSortOptions), {
      expiry: this.cache.maxExpiryDate(),
      persist: true
    });
    closeEmberDrop();
  }

  @action
  updateSelectedInstagramPostTypes(option: EmberDropMultiSelectOption): void {
    this.selectedInstagramPostTypes = this.selectedInstagramPostTypes.includes(option.name)
      ? tracked(this.selectedInstagramPostTypes.filter((selectedOption: string) => selectedOption !== option.name))
      : tracked([...this.selectedInstagramPostTypes, option.name]);
    this.cache.add(this.instagramFilterCacheKey, JSON.stringify(this.selectedInstagramPostTypes), {
      expiry: this.cache.maxExpiryDate(),
      persist: true
    });
  }

  @action
  updateSelectedPostStates(option: EmberDropMultiSelectOption): void {
    this.selectedPostStates = this.selectedPostStates.includes(option.name)
      ? tracked(this.selectedPostStates.filter((selectedOption: string) => selectedOption !== option.name))
      : tracked([...this.selectedPostStates, option.name]);
    this.cache.add(this.filterCacheKey, JSON.stringify(this.selectedPostStates), {
      expiry: this.cache.maxExpiryDate(),
      persist: true
    });
  }

  @action
  updateTimePickerConfig(startDate?: Moment, endDate?: Moment): void {
    if (startDate && endDate) {
      this.startDate = startDate.startOf('day');
      this.endDate = endDate.endOf('day');
      this.cache.add(this.postDatesCacheKey, JSON.stringify({ start: startDate, end: endDate }), {
        expiry: this.cache.maxExpiryDate(),
        persist: true
      });
    }
  }

  // Note: Update selected dates to include posts pending approval unless they're already within the desired date range
  #displayPendingApprovalDates(): void {
    if (this.postsPendingApproval.dateRange) {
      const pendingApprovalDateRange = {
        start: this.postsPendingApproval.dateRange?.start.toDate(),
        end: this.postsPendingApproval.dateRange?.end.toDate()
      };
      const currentDateRange = {
        start: this.startDate.toDate(),
        end: this.endDate.toDate()
      };
      const alreadyWithinDates = isWithinDates(
        pendingApprovalDateRange.start,
        pendingApprovalDateRange.end,
        currentDateRange
      );
      if (!alreadyWithinDates) {
        this.updateTimePickerConfig(this.postsPendingApproval.dateRange.start, this.postsPendingApproval.dateRange.end);
      }
    }
  }

  #getItemIdFromDragEvent(dragEvent: DragEvent): string {
    const { target, dataTransfer } = dragEvent;

    if (!target || !dataTransfer) {
      this.errors.log('Invalid `DragEvent` provided');
      return '';
    }

    const draggedElement = target as HTMLElement;
    const droppedItemIds = dataTransfer.getData('text');

    if (draggedElement.classList.contains('e--post__new') || draggedElement.classList.contains('e--post__scheduled')) {
      this.errors.log('Unsupported element dropped - only posts in sidebar allowed');
      return '';
    }

    return droppedItemIds;
  }

  // Note: Get the current route name without a `.index` suffix (if present).
  #getCurrentRouteName(): string {
    return this.router.currentRouteName.replace(/\.index$/, '');
  }

  #handlePossibleFeatureFlagUpdates(): void {
    const approvalFilterNames = this.approvalFilters.map((approvalFilter) => approvalFilter.name);
    const selectedPostStatesIncludesApprovalFilters = this.selectedPostStates.any((postState) =>
      approvalFilterNames.includes(postState)
    );
    if (selectedPostStatesIncludesApprovalFilters && !this.auth.currentAccount.canApprovalFlow) {
      const filteredPostStates = this.selectedPostStates.filter(
        (postState) => !approvalFilterNames.includes(postState)
      );
      this.selectedPostStates = tracked(filteredPostStates);
    }
  }

  // Note: Approval States are differentiated from regular states in that they use name and approval status for comparison instead of value and post state,
  // because they're being shoehorned into this existing filter functionality, we have to ensure that we handle them in isolation from one another despite similar
  // functionality from the customer perspective
  #filterSelectedPostStates(
    { approvalStates, selectedStates } = { approvalStates: false, selectedStates: false }
  ): (string | undefined)[] {
    const approvalFilterNames = this.approvalFilters.map((filter: EmberDropMultiSelectOption) => filter.name);
    const filteredStatesByApproval = this.selectedPostStates.filter((postStateName: string) =>
      approvalStates ? approvalFilterNames.includes(postStateName) : !approvalFilterNames.includes(postStateName)
    );
    const filteredStatesBySelected = filteredStatesByApproval.map((postStateName: string) => {
      const selectedState = this.postStates.find(
        (postState: EmberDropMultiSelectOption) => postState.name === postStateName
      );
      return approvalStates ? selectedState?.name : selectedState?.value;
    });
    return selectedStates ? filteredStatesBySelected : filteredStatesByApproval;
  }

  #filterPosts(posts: MutableArray<GramModel>): MutableArray<GramModel> {
    const selectedProfileIds = this.selectedSocialProfiles.profileIds;
    const displayedPostStates = this.#filterSelectedPostStates({ approvalStates: false, selectedStates: true });
    const displayedPostApprovalStates = this.#filterSelectedPostStates({ approvalStates: true, selectedStates: true });
    const displayedInstagramPostTypes = this.selectedInstagramPostTypes.map((instagramOptionName: string) => {
      return this.instagramPostTypes.find(
        (instagramOption: EmberDropMultiSelectOption) => instagramOption.name === instagramOptionName
      )?.value;
    });

    return posts.filter((post: GramModel) => {
      if (post.startTime && post.state) {
        const endTime = moment(post.startTime).add(30, 'minutes');
        const socialProfile = post.get('socialProfile');
        const postIsWithinDates = isWithinDates(post.startTime.toDate(), endTime.toDate(), this.dateRange);
        const retryingWithPlatformError =
          post.state === GramState.Retrying && post.platformError && displayedPostStates.includes(GramState.Failed);
        const postHasValidState = displayedPostStates.length
          ? (post.state && displayedPostStates.includes(post.state)) || retryingWithPlatformError
          : true;
        const postHasValidApprovalState = displayedPostApprovalStates.length
          ? this.#hasValidApprovalState(displayedPostApprovalStates, post)
          : true;
        const postHasValidStateAndApprovalState =
          displayedPostStates.length && displayedPostApprovalStates.length
            ? postHasValidState || postHasValidApprovalState
            : postHasValidState && postHasValidApprovalState;
        const shouldApplyInstagramFilters = Boolean(
          displayedInstagramPostTypes.length && this.shouldDisplayInstagramFilters
        );
        const instagramPostHasValidType = shouldApplyInstagramFilters
          ? displayedInstagramPostTypes.includes(post.type)
          : true;
        const postIsOfSelectedProfile = selectedProfileIds.includes(socialProfile.get('id') || '');

        return (
          postIsWithinDates && postIsOfSelectedProfile && postHasValidStateAndApprovalState && instagramPostHasValidType
        );
      }
      return false;
    });
  }

  #hasValidApprovalState(displayedPostApprovalStates: (string | undefined)[], post: GramModel): boolean {
    const approvalStateChecks = [
      {
        state: this.intl.t('post.state.internal_review_approved'),
        condition: post.isApproved
      },
      {
        state: this.intl.t('post.state.internal_review_pending'),
        condition: post.pendingApproval
      },
      {
        state: this.intl.t('post.state.external_review_pending'),
        condition: post.isSubmittedExternalReview && !post.isExternalCommentsCollected
      },
      {
        state: this.intl.t('post.state.external_review_collected'),
        condition: post.isExternalCommentsCollected
      }
    ];

    return approvalStateChecks.some(({ state, condition }) => displayedPostApprovalStates.includes(state) && condition);
  }

  #setSelectedStatesFromQueryParams(): void {
    if (this.initial_states) {
      try {
        const initialStates = this.initial_states.split(',');
        const postStatesToSelect = this.postStates.filter((postState: EmberDropMultiSelectOption): boolean =>
          Boolean(postState.value && initialStates.includes(postState.value))
        );
        this.selectedPostStates = tracked(
          postStatesToSelect.map((postState: EmberDropMultiSelectOption) => postState.name)
        );
      } catch (error) {
        this.errors.log('setSelectedStatesFromQueryParams Error', error);
      }
    }
  }

  #trackClickedApprovalList(): void {
    this.segment.track(SegmentEventTypes.ClickedPostApprovalList);
  }

  #transitionToEdit(postId: string): void {
    const redirectName = this.#getCurrentRouteName();
    this.router.transitionTo('cluster.schedule.list.post.edit', postId, {
      queryParams: { backTo: redirectName }
    });
  }

  #transitionToNew(mediaItemIds: string): void {
    const redirectName = this.#getCurrentRouteName();
    const isMultiProfile = this.selectedProfiles.length > 1;

    if (isMultiProfile) {
      this.router.transitionTo('cluster.schedule.list.post.multi', mediaItemIds, {
        queryParams: {
          backTo: redirectName,
          targetDate: Date.now(),
          scheduledTime: this.postTimeFromCurrentDate
        }
      });

      return;
    }

    this.router.transitionTo('cluster.schedule.list.post.new', mediaItemIds, {
      queryParams: {
        backTo: redirectName,
        targetDate: Date.now(),
        scheduledTime: this.postTimeFromCurrentDate
      }
    });
  }

  #trackViewedPage(): void {
    this.segment.track(SegmentEventTypes.ViewedPage, {
      can_boost_post: this.posts?.any((post) => post.canBoostPost),
      page: 'list_view'
    });
  }

  #trackLoadedPost(): void {
    this.segment.track(SegmentEventTypes.LoadedPost, {
      area: 'list_view',
      can_boost_post: this.posts?.any((post) => post.canBoostPost)
    });
  }

  fetchPosts = task(async () => {
    if (!this.endDate || !this.startDate) {
      return;
    }

    await this.fetchWithinDates.fetchRecords.perform(this.postsFromStore, FetchableRecordTypes.Posts, this.dateRange);
    await this.fetchWithinDates.prefetch.perform(new Date(), FetchableRecordTypes.Posts);
    if (this.auth.currentAccount.canApprovalFlow) {
      await this.fetchPostActivities.perform();
    }
    this.#trackLoadedPost();
  });

  fetchPostActivities = task(async () => {
    try {
      await Promise.all(
        this.posts.map(async (post) => {
          const activities = await this.store.query('post-activity', { post_id: post.id });
          post.postActivities.pushObjects(activities.toArray());
        })
      );
    } catch (error) {
      this.errors.log('Failed to fetch post activities', error);
    }
  });

  loadPostsAndSettings = task(async () => {
    this.setPostNavigation();
    this.#setSelectedStatesFromQueryParams();
    this.getDatesFromCache();
    this.getFiltersFromCache();
    this.getSortOrderFromCache();
    this.#handlePossibleFeatureFlagUpdates();
    await this.fetchPosts.perform();
  });
}
